import React from "react";
import dino1 from "./content_data/Aleksi_Epic_Wallpaper_Mecha-T-rex_Steampunk_Cyan-Magenta_backli_659abce9-960d-47ca-baf2-6962a7892e9a.png";
import dino2 from "./content_data/Aleksi_T-rex_Cyborg_with_Dragon_wings_flying_with_moon_backligh_11e45676-aa6a-46e8-bdf6-e0df51bb37dc.png";
import { Avaturn, ModelViewer } from "../components";

const Content = () => {
  return (
    <div className="m-2 rounded-2">
      <div className="container-fluid onTop rounded-2 p-2 shadow">
        <div className="card m-2 bg-dark text-white">
          <div className="card-body">
            <h5 className="card-title">Fait par un AI 😍</h5>
            <img
              src={dino1}
              style={{ maxWidth: "200px", height: "auto" }}
              className="img-responsive rounded-3 imghover m-2"
              alt="dino"
            ></img>
            <img
              src={dino2}
              style={{ maxWidth: "200px", height: "600" }}
              className="img-responsive rounded-3 imghover m-2"
              alt="dino"
            ></img>
            <p className="card-text">
              
              
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid onTop rounded-2 mt-3 shadow"><div style={{height:"500px", overflow:"none"}}><ModelViewer /></div></div>
      
      <div id="stars">
        <div id="stars2">
          <div id="stars3"></div>
        </div>
      </div>
    </div>
  );
};

export default Content;
