import React from "react";
import { GiDragonSpiral, GiEvilMoon } from "react-icons/gi";
import { IconContext } from "react-icons";

const TopNavbar = () => {
  return (
    <nav class="container p-3 justify-content-end d-flex onTop m-2 mt-2 ps-4 rounded-3 shadow">
      <div className="notification text-white me-2">
        <IconContext.Provider
          value={{ color: "#FF0E89", size: '1.5em' }}
        >
          <div>
            <GiDragonSpiral />
          </div>
        </IconContext.Provider>
      </div>
      <div className="darktheme text-white me-2">
      <IconContext.Provider
          value={{ color: "#FEFCD7", size: '1.5em' }}
        >
          <div className="flipH">
            <GiEvilMoon />
          </div>
        </IconContext.Provider>
      </div>
      <input type="text" className="rounded-4" placeholder=" Recherche..." />
    </nav>
  );
};

export default TopNavbar;
