import React from "react";
import model from "./content_data/Jump/Le Bonheur.glb";

function ModelViewer() {
  return (
    <model-viewer
      src={model}
      camera-controls
      shadow-intensity={0}
      autoPlay
      camera-target="4m 3m 3m"
      camera-orbit="418.4deg 78.84deg 18.98m"
      field-of-view="18.9deg"
      exposure="0.55"
      shadow-softness="0.39"
    >
      <div className="progress-bar hide" slot="progress-bar">
        <div className="update-bar" />
      </div>
    </model-viewer>
  );
}

export default ModelViewer;
