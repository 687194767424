import "./App.css";
import { Navbar, TopNavbar, Content } from "./components";

function App() {
  return (
      <div className="container-fluid g-0 m-0 fitscale">
        <div className="row g-0 pt-3 p-2 nbg fitscale flex-row flex-nowrap">
          <div className="col d-none d-md-block" style={{maxWidth:'250px'}}>
            <Navbar />
          </div>
          <div className="col">
            <div className='row g-0 me-4'>
            <TopNavbar />
            </div>
            <div className='row g-0 me-4'>
            <Content />
            </div>            
          </div>
        </div>
      </div>
  );
}

export default App;
