import React from "react";
//import { Link } from "react-router-dom";
import logo from './content_data/Navlogo.png';

const Navbar = () => {
  return (
    <div className="vertical-navbar onTop m-2 ms-3 mx-1 rounded-3 shadow">
      <div>
        <div className="text-white text-center p-2 pt-1">
          <img
            src={logo}
            alt="logo"
            className="image-fluid"
            style={{maxWidth:'200px',height:'auto'}}
          />
        </div>
        <div className="navbar-nav text-white">
          <ul>
            <div className="ms-2">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">
                  Accueil
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Outils
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Démo
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" href="/">
                アウトティル
                </a>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
